<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">通讯录</div>
      <div class="topRight">
        <img src="../../image/quanz/jhy.png" alt="" @click="goSeekFriend()" />
      </div>
    </div>
    <div class="searBox">
      <img src="../../image/quanz/s.png" alt=""/>
      <input type="text" placeholder="请输入成员昵称" v-model="searchNick" v-on:keyup.enter="searchFriend"/>
    </div>
    <div class="fsNum" @click="goApplyForPage()">
      <img src="../../image/quanz/hy.png" alt="" />好友申请
    </div>
    <div class="line"></div>
<!--    <div class="container">-->
<!--      <div class="fansHear"></div>-->
<!--      <div class="nameBrief">-->
<!--        <div>低调的先生</div>-->
<!--        <div>生活小达人</div>-->
<!--      </div>-->
<!--      <div class="gzStyle"><img src="../../image/quanz/fsx.png" alt="" /></div>-->
<!--      <div class="gdStyle">-->
<!--        <img src="../../image/quanz/gd.png" alt="" @click="showPopup()" />-->
<!--      </div>-->
<!--    </div>-->
      <div class="container" v-for="item in friendList" :key="item.userID">
        <div class="fansHear">
          <img :src="item.profile.avatar"/>
        </div>
        <div class="nameBrief">
          <div>{{ item.remark ==='' ? item.profile.nick : item.remark }}</div>
          <div>{{ item.profile.selfSignature }}</div>
        </div>
        <div class="gzStyle"><img src="../../image/quanz/fsx.png" alt="" @click="goChatPage(item.userID)"/></div>
        <div class="gdStyle">
          <img src="../../image/quanz/gd.png" alt="" @click="showPopup(item.userID, item.remark)" />
        </div>
      </div>
    <van-popup v-model="show" round position="bottom">
      <div class="popupDiv" @click="lhEveent()">拉黑</div>
<!--      <div class="popupDiv">举报/投诉</div>-->
      <div class="popupDiv">设置备注和昵称</div>
      <div class="popupDiv2" @click="deleteFriend()">解除好友关系</div>
      <div class="line"></div>
      <div class="popupDiv1" @click="show = false">取消</div>
    </van-popup>

    <van-overlay :show="showOver" @click="showOver = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="blockTop">将“{{ operatorNick }}”移除黑名单</div>
          <div class="blockContent">
            移除后对方将可以关注你、评论或点赞你的圈子、向你发私信等。
          </div>
          <div class="blockBtn">
            <div @click="showOver = false">取消</div>
            <div @click="removeEvent()">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      showOver: false,
      friendList: JSON.parse(sessionStorage.getItem("friendList")),
      thisUserId: this.$store.state.user.id + "",
      operatorUserId: '',
      operatorNick: '',
      searchNick: '',
      backFriends: '',
    }
  },
  mounted() {
    let user = this.$store.state.user;
    let tis = this;
    if (user.circleAuth == 2) {
      if (!this.tim.isReady()) {
        //SDK 进入 ready 状态时触发，接入侧监听此事件，然后可调用 SDK 发送消息等 API，使用 SDK 的各项功能。
        let onSdkReady = function(event) {
          // console.log("address-onSdkReady", event)
          tis.getFriendList()
        };
        this.tim.on(this.Tim.EVENT.SDK_READY, onSdkReady);
        //SDK 进入 not ready 状态时触发，此时接入侧将无法使用 SDK 发送消息等功能。如果想恢复使用，接入侧需调用 login 接口，驱动 SDK 进入 ready 状态。
        let onSdkNotReady = function(event) {
          // console.log("address-onSdkNotReady", event)
          tis.imLogin();
        };
        this.tim.on(this.Tim.EVENT.SDK_NOT_READY, onSdkNotReady);
        tis.imLogin();
      } else {
        tis.getFriendList();
      }
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // 回复
    hfEvent() {},
    // 更多
    showPopup(userId, remark) {
      this.operatorUserId = userId;
      this.operatorNick = remark;
      this.show = true;
    },
    // 拉黑弹框
    lhEveent() {
      // this.addBlack(this.operatorUserId);
      this.showOver = true;
      this.show = false;
    },
    // 确定拉黑
    removeEvent() {
      this.show = false;
      this.showOver = false;
      this.addBlack(this.operatorUserId)
    },
    // 跳转好友申请
    goApplyForPage() {
      this.$router.push({ path: "/applyForPage" });
    },
    // 发私信
    goChatPage(userId) {
      this.$router.push({ path: "/chatPage", query:{"userId": userId } });
    },
    imLogin() {
      let user = this.$store.state.user;
      if (user.circleAuth == 2 && !this.tim.isReady()) {
        let loginPromise = this.tim.login({userID: user.id + "", userSig: user.imSig});
        loginPromise.then(function (imResponse) {
          if (imResponse.data.repeatLogin === true) {
            // 标识账号已登录，本次登录操作为重复登录。
            console.log(imResponse.data.errorInfo);
          }
        }).catch(function (imError) {
          console.warn('login error:', imError); // 登录失败的相关信息
        });
      }
    },
    getFriendList() {
      if (this.tim.isReady()) {
        let tis = this;
        let promise = tis.tim.getFriendList();
        promise.then(function(imResponse) {
          console.log("getFriendList", imResponse)
          // console.log(imResponse.data.length)
          if (imResponse.data.length > 0) {
            let friends = imResponse.data.filter((x) => x.userID !== tis.thisUserId);
            sessionStorage.setItem("friendList", JSON.stringify(friends));
            tis.friendList = friends; // 好友列表
            tis.backFriends = friends;
          }
          // console.log(tis.friendList)
        }).catch(function(imError) {
          console.warn('getFriendList error:', imError); // 获取好友列表失败的相关信息
        });
      }
    },
    // 修改好友备注
    setUserProfile(remark) {
      // 更新好友备注
      let promise = this.tim.updateFriend({
        userID: this.operatorUserId,
        remark: remark
      });
      promise.then(function(imResponse) {
        console.log(imResponse.data); // Friend 实例
      }).catch(function(imError) {
        console.warn('getFriendProfile error:', imError); // 更新失败
      });
    },
    // 删除好友
    deleteFriend() {
      let tis = this;
      let promise = this.tim.deleteFriend({
        userIDList: [tis.operatorUserId],
        type: this.Tim.TYPES.SNS_DELETE_TYPE_BOTH
      });
      promise.then(function(imResponse) {
        const { successUserIDList, failureUserIDList } = imResponse.data;
        // 删除成功的 userIDList
        successUserIDList.forEach((item) => {
          const { userID } = item;
        });
        // 删除失败的 userIDList
        failureUserIDList.forEach((item) => {
          const { userID, code, message } = item;
        });
        // 如果好友列表有变化，则 SDK 会触发 TencentCloudChat.EVENT.FRIEND_LIST_UPDATED 事件
      }).catch(function(imError) {
        console.warn('removeFromFriendList error:', imError);
      });
    },
    // 拉黑
    addBlack(operatorUserID) {
      // 请注意：即使只添加一个用户账号到黑名单，也需要用数组类型，例如：userIDList: ['user1']
      let promise = this.tim.addToBlacklist({userIDList: [operatorUserID]});
      promise.then(function(imResponse) {
        console.log(imResponse.data); // 成功添加到黑名单的账号信息，结构为包含用户 userID 的数组 - [userID]
      }).catch(function(imError) {
        console.warn('addToBlacklist error:', imError); // 添加用户到黑名单列表失败的相关信息
      });
    },
    // 查找好友
    goSeekFriend() {
      this.$router.push({ path: "/seekFriend" });
    },
    // 根据昵称查询好友
    searchFriend() {
      if (this.friendList) {
        this.friendList = this.backFriends.filter((x) => x.remark.startsWith(this.searchNick));
      }
    }
  },
};
</script>

<style scoped>
.boxTop {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 236px;
}
.topRight img {
  display: flex;
  width: 172px;
  height: 56px;
  margin-left: 134px;
}
.searBox {
  width: 686px;
  height: 72px;
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-left: 32px;
  margin-top: 34px;
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  display: flex;
  align-items: center;
}
.searBox img {
  width: 32px;
  height: 32px;
  margin-left: 24px;
  margin-right: 12px;
}
.searBox input {
  width: 500px;
  border: none;
  background: #f6f8fa;
}
.fsNum {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 32px;
  margin-top: 38px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.fsNum img {
  width: 104px;
  height: 104px;
  margin-right: 16px;
}
.line {
  width: 750px;
  height: 20px;
  background: #f6f8fa;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.container {
  width: 686px;
  height: 144px;
  border-bottom: 1px solid #f6f8fa;
  margin-left: 32px;
  display: flex;
  align-items: center;
  /* padding-top: 32px;
    box-sizing: border-box; */
}
.fansHear {
  width: 80px;
  height: 80px;
  //background: red;
  border-radius: 50%;
  overflow: hidden;
}
.fansHear img {
  height: 100%;
  //border-radius: 50%;
}
.nameBrief {
  width: 370px;
  margin-left: 20px;
}
.nameBrief div:nth-child(1) {
  font-size: 28px;
  font-weight: 600;
  color: #0b1526;
}

.nameBrief div:nth-child(2) {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 6px;
}
.gzStyle img {
  width: 120px;
  height: 56px;
}
.gdStyle img {
  width: 40px;
  height: 40px;
  display: flex;
  margin-left: 48px;
}
.popupDiv,
.popupDiv1,
.popupDiv2 {
  width: 750px;
  height: 104px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  text-align: center;
  line-height: 104px;
  border-bottom: 1px solid #f6f8fa;
}
.popupDiv1 {
  border: none;
}
.popupDiv2 {
  color: #fa2819;
}
.line {
  width: 750px;
  height: 20px;
  background: #f6f8fa;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 510px;
  height: 346px;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  opacity: 1;
}
.blockTop {
  width: 510px;
  height: 88px;
  border-bottom: 1px solid #f6f8fa;
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  text-align: center;
  line-height: 88px;
}
.blockContent {
  font-size: 28px;
  width: 446px;
  font-weight: 400;
  color: #0b1526;
  margin-left: 32px;
  margin-top: 20px;
}
.blockBtn {
  display: flex;
  margin-top: 32px;
}
.blockBtn div:nth-child(1) {
  width: 160px;
  height: 84px;
  background: #f6f8fa;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  text-align: center;
  line-height: 84px;
  margin-left: 32px;
}
.blockBtn div:nth-child(2) {
  width: 262px;
  height: 84px;
  background: #0957c3;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 84px;
  margin-left: 24px;
}
</style>
